<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="LESSONS"
      tooltip-title="LESSONS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.lesson_number"
            class="flex-1"
            title="LESSON_N"
            type="text"
            rules="numeric"
            placeholder="LESSON_N"
            :filter="{
              option: FILTER_KEYS.EQUAL,
              key: 'lesson_number',
            }"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model="filtersPayload.type"
            title="Lesson Type"
            :options="GENERAL_CONSTANTS.LESSON_TYPES"
            label="title"
            class="flex-1"
            :filter="{
              option: FILTER_KEYS.EQUAL,
              key: 'type',
            }"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.subject_id"
            title="Select Subject"
            :options="subjectList"
            label="title"
            placeholder="Select Subject"
            class="flex-1"
            :class="$style.widthItems"
            reduce="id"
            :filter="FILTERS_LIST.SUBJECT"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="getSubjectList"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.chapter_id"
            title="SELECT_CHAPTER"
            :options="chaptersList"
            label="title"
            placeholder="SELECT_CHAPTER"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            :filter="FILTERS_LIST.CHAPTER"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="_getChapters"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.topic_id"
            title="SELECT_TOPIC"
            :options="topicsList"
            label="title"
            placeholder="SELECT_TOPIC"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            :filter="FILTERS_LIST.TOPIC"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="_getTopics"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'

import TitleWrapper from '@src/components/TitleWrapper.vue'
import UiSingleSelect from '@src/components/UiElements/UiSingleSelect.vue'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { mapActions, mapState } from 'vuex'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      FILTER_KEYS,
      FILTERS_LIST,
      $where: {},
      queries: {},
      subjectList: [],
      chaptersList: [],
      topicsList: [],
      lessonsList: [],
      title: 'title',
      appliedQueries: {},
      filtersCount: 0,
      filtersPayload: {
        title: '',
        lesson_number: '',
        type: '',
        subject_id: '',
        chapter_id: '',
        topic_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState('classes', ['classesList']),
    ...mapState({
      sectionId: (state) => state.layout.currentSectionScope.id,
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },
  watch: {
    campus: {
      handler() {
        this.clearFilters()
      },
    },
    'filtersPayload.subject_id': {
      handler() {
        this.filtersPayload.chapter_id = ''
        this.chaptersList = []
        this.topicsList = []
        this.filtersPayload.topic_id = ''
        this._getChapters()
        this._getTopics()
      },
    },
    'filtersPayload.chapter_id': {
      handler() {
        this.topicsList = []
        this.filtersPayload.topic_id = ''
        this._getTopics()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getSubjectList()
        this._getChapters()
        this._getTopics()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getSubjectList()
    this._getChapters()
    this._getTopics()
  },
  methods: {
    _getChapters(query = '') {
      let filtersPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.filtersPayload.subject_id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getChapters(filtersPayload).then((res) => {
        this.chaptersList = res.records
      })
    },
    _getTopics(query = '') {
      this.topicsList = []
      let filtersPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.filtersPayload.subject_id),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'chapter_id', this.filtersPayload.chapter_id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getTopics(filtersPayload).then((res) => {
        this.topicsList = res.records
      })
    },
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp.data?.records || []
      })
    },
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['getChapters', 'getTopics']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>

<template>
  <div>
    <LessonsFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div class="main-content minHieght80 rounded-t-lg">
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && lessonList && !lessonList.length">
        <NoRecordFound />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH
              v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
              v-show="
                showColumnInDataTable(filtersData, head.dataTableName, [
                  'Lesson',
                  'Lesson Number',
                  'Lesson Type',
                  'Scheduled at',
                ])
              "
              :key="head.id"
            >
              {{ head.dataTableName }}
            </TH>
            <TH v-permission="tableActionsPermissions" v-i18n="dashboard">Actions</TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(lesson, index) in lessonList" :key="index">
            <TD v-show="showColumnInDataTable(filtersData, 'Lesson', ['Lesson'])">
              {{ lesson.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Lesson Number', ['Lesson Number'])">
              {{ lesson.lesson_number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Lesson Type', ['Lesson Type'])">
              {{ lesson.type || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Description')">
              {{ lesson.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>

            <TD v-show="showColumnInDataTable(filtersData, 'Scheduled at', ['Scheduled at'])">
              <div>
                <template v-if="lesson.periods && lesson.periods.length">
                  <p v-for="(period, idx) in lesson.periods" :key="idx">
                    {{ getTime(period.started_at, period.ended_at) }}
                  </p>
                </template>
                <p v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</p>
              </div>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Subject')">
              {{ lesson.topic.chapter.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Chapter')">
              {{ lesson.topic.chapter.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Topic')">
              {{ lesson.topic.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
              v-permission="tableActionsPermissions"
            >
              <TableAction
                :key="lesson.id"
                :current-user="lesson"
                :idx="index"
                :action-list="actionList"
                :status="lesson.status"
                @action="lessonAction"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="showPagination"
        v-show="!isLoading"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(lessonCounts, filteredRecordLimit)"
        @filterRecord="filtersRecords"
      />
    </div>
    <LessonModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentRecord ? currentRecord : null"
      @toggle="toggleModal"
    ></LessonModal>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState } from 'vuex'
import generalMixins from '@src/mixins/general-mixins.js'
import LessonModal from '@src/router/views/lesson-planner/lesson/lesson-modal.vue'
import LessonsFilter from '@views/lesson-planner/lesson/LessonsFilter.vue'
import {
  removeEmptyKeysFromObject,
  objectDeepCopy,
  showColumnInDataTable,
} from '@utils/generalUtil'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@/src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { formatDate } from '@src/utils/moment.util.js'
import lessonsData from '@src/router/views/lesson-planner/lesson/lessons.json'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'Lesson',
  components: {
    Loader,
    TableAction,
    LessonModal,
    Pagination,
    LessonsFilter,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixins, scrollMixin],
  data() {
    return {
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      GENERAL_CONSTANTS,
      selectedTopic: '',
      attendanceTranslation: 'attendanceTranslation',
      defaultSelectedColumns: lessonsData.defaultSelectedColumns,
      tableHeaders: lessonsData.tableHeaders,
      title: 'title',
      dashboard: 'dashboard',
      options: ['Select'],
      chaptersList: [],
      topicsList: [],
      isLoading: false,
      filtersData: {},
      actionList: [{ name: 'Edit' }],
      currentRecord: null,
      subjectList: [],
      selectedSubject: '',
      selectedChapter: '',
      lessonList: [],
      statusColor: [
        { text: '#267C26', bg: '#E9F2E9' },
        { bg: '#FFF9E6', text: '#FFC201' },
        { bg: '#FCE1E1', text: '#EA3535' },
        { bg: '#E3E4E8', text: '#777D8B' },
      ],
      statustextcolor: ['#267C26', '#FFC201', '#EA3535', '#777D8B'],
      lessonCounts: '',
      showPagination: false,
      filteredRecordLimit: 10,
    }
  },
  computed: {
    isCampusOrSectionChange() {
      return this.sectionId + this.campusId
    },
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      sectionId: (state) => state.layout.currentSectionScope.id,
      showModal: (state) => state.layout.showModal,
      campusId: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    isCampusOrSectionChange: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.lessonList = []
        })

        this.setRightBarData()
        this.filtersRecords()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchLessonStats()
      },
    },
  },
  page: {
    title: 'Syllabus | Lesson',
    meta: [
      {
        name: 'description',
        content: 'Manage  Syllabus of each Lesson ',
      },
    ],
  },
  methods: {
    showColumnInDataTable,
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('chapter', ['deleteChapter']),
    ...mapActions('lesson', ['getLessons', 'getLessonStats', 'deleteLesson']),
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filtersRecords()
    },
    getTime(startdate, endDate) {
      return formatDate(startdate, 'DD-M-YYYY ( hh:mm a') + formatDate(endDate, ' - hh:mm a )')
    },
    statusStyle(status) {
      var res = {}
      if (status.toLowerCase() === 'complete') res = { text: '#267C26', bg: '#E9F2E9' }
      if (status.toLowerCase() === 'p.complete') res = { bg: '#FFF9E6', text: '#FFC201' }
      if (status.toLowerCase() === 'missed') res = { bg: '#FCE1E1', text: '#EA3535' }
      if (status.toLowerCase() === 'incomplete') res = { bg: '#E3E4E8', text: '#777D8B' }

      return res
    },
    lessonAction(actionName, current, idx, toggle) {
      this.currentRecord = current
      switch (actionName) {
        case 'Edit':
          this.toggleModal()
          break
        case 'Delete':
          this.deleteLesson(this.currentRecord.id).then(() => {
            this.filtersRecords()
            this.currentRecord = null
          })
          break
      }
    },
    setRightBarData(count = 0) {
      this.setRightbarContent({
        header: {
          title: 'Lessons',
          buttons: [
            {
              title: 'Add New Lesson',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
              permissions: {
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.CAMPUS_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                ],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              { text: 'TOTAL_LESSONS', value: count, tooltipDescription: 'Total no.of Lessons' },
            ],
          },
        ],
      })
    },
    fetchLessonStats(payload = { $where: this.filtersData?.$where }) {
      this.getLessonStats(payload).then((res) => {
        let count = res?.meta?.total_lesson_count
        this.setRightBarData(count)
      })
    },

    filtersRecords(range) {
      this.lessonList = []
      this.isLoading = true
      let paginationRange = paginationRangeHandler(range)
      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      removeEmptyKeysFromObject(payload)
      this.getLessons(payload)
        .then((res) => {
          this.lessonList = res.records
          this.lessonCounts = res.meta.total_records
          this.showPagination = this.lessonCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        })

        .finally(() => {
          this.fetchLessonStats()
          this.isLoading = false
        })
    },
    toggleModal(payload = null) {
      this.setShowModal()
      if (payload) this.filtersRecords()
      if (!this.showModal) this.currentRecord = null
    },
  },
}
</script>
